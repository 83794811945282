<template>
  <page-container title="项目巡查情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepid">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="queryParams.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="巡查类型" prop="patroltype">
              <a-select v-model="queryParams.patroltype">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="1">视频巡查</a-select-option>
                <a-select-option value="2">综合巡查</a-select-option>
                <a-select-option value="3">工程巡查</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
            <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
              <a-form-model-item label="月份" prop="time_pr">
                <div style="display: flex;flex-direction: row;gap: 10px">
                  <a-month-picker :value="queryParams.start_month" @change="startMonthChange" placeholder="请选择开始月份"/>
                  <a-month-picker :value="queryParams.end_month" @change="endMonthChange" placeholder="请选择结束月份"/>
                </div>
              </a-form-model-item>
            </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">项目巡查情况列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
              <span slot="patroltype" slot-scope="text" >
                <span>{{ typeList[text] }}</span>
              </span>
          <span slot="people_type" slot-scope="text" class="propertyProjectInspections_people_type-view">
            <span class="dot" :class="{'green': text===1}"></span>
            <span class="text">{{ text === 0 ? '使用人' : '业主' }}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改垃圾处理页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="公司/项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'"  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="统计月份" prop="month">
          <a-month-picker :disabled="modalType==='detail'" :value="modalForm.month" @change="monthChange" placeholder="请选择月份"/>
        </a-form-model-item>
        <a-form-model-item label="巡查类型" prop="patroltype">
          <a-select :disabled="modalType==='detail'" v-model="modalForm.patroltype">
            <a-select-option value="1">视频巡查</a-select-option>
            <a-select-option value="2">综合巡查</a-select-option>
            <a-select-option value="3">工程巡查</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="巡查线路数" prop="patrollinenum">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.patrollinenum" type="number"></a-input>
        </a-form-model-item>
        <a-form-model-item label="巡查次数" prop="patrolnum">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.patrolnum" type="number"></a-input>
        </a-form-model-item>
        <a-form-model-item label="巡查天数" prop="patroldaynum">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.patroldaynum" type="number"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformancePropertyProjectInspection,
  getPropertyProjectInspectionListByCondition,
  deleteOnePropertyProjectInspection,
  editPropertyProjectInspection
} from 'A/performance'
import { getDictByDicType} from 'A/xtpz'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {userdep} from "V/dataAnalysis/performance/minins/userdep";
import {getMonitorPointNameListByCondition} from "A/monitoring";

export default {
  name: 'propertyProjectInspection',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '物业服务工作',
          path: ''
        },
        {
          name: '项目巡查情况',
          path: ''
        }
      ],
      queryParams: {
        start_month:null,
        end_month:null,
        patroltype: '',
        // patrollinenum: '',
        // patrolnum: '',
        // patroldaynum:'',
        userdepid:'',
        selectDeptList:[]
      },
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '月份',
          dataIndex: 'month',
          key: 'month'
        },
        {
          title: '巡查类型',
          dataIndex: 'patroltype',
          key: 'patroltype',
          scopedSlots: {customRender: 'patroltype'}
        },
        {
          title: '巡查线路数',
          dataIndex: 'patrollinenum',
          key: 'patrollinenum',

        },
        {
          title: '巡查次数',
          dataIndex: 'patrolnum',
          key: 'patrolnum'
        },
        {
          title: '巡查天数',
          dataIndex: 'patroldaynum',
          key: 'patroldaynum',
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        month:null,
        patroltype: '',
        patrollinenum: '',
        patrolnum: '',
        patroldaynum:'',
        userdepid:"",
        monitorpointnum:"",
        selectDeptList:[],
      },
      modalRules: {
        month: [{required: true, message: '请选择月份'}],
        patroltype: [{required: true, message: '请选择巡查类型'}],
        patrollinenum: [{required: true, message: '请输入巡查线路数'}],
        patrolnum: [{required: true, message: '请输入巡查次数'}],
        patroldaynum: [{required: true, message: '请输入巡查天数'}],
        userdepid: [{required: true, message: '请选择公司/项目'}]
      },
      selectedPropertyProjectInspection:null,
      unitList:[],
      subtypeList:[],
      monitorpointList:[],
      typeList:{1:'视频巡查',2:'综合巡查',3:'工程巡查'}

    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
    //查询垃圾处理单时间
    query_begin_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[0]) {
        return moment(this.queryParams.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    query_endtime() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[1]) {
        return moment(this.queryParams.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    //添加垃圾处理单时间
    add_happen_time() {
      if (this.modalForm.happen_time) {
        return moment(this.modalForm.happen_time).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        // this.queryParams.userdepid = val.join('|')
        // this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    this.init();
  },
  methods: {
    init() {
      this.getMonitorPointNameList()
      this.getTableData();
    },
    resetQueryParams() {
      this.queryParams.selectDeptList=[]
      this.queryParams.start_month=null
      this.queryParams.end_month=null
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getMonitorPointNameList() {
      getMonitorPointNameListByCondition({userdepsid: this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.monitorpointList = res.item
        }
      })
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      if(this.queryParams.start_month&&this.queryParams.end_month){
        // 将时间字符串转换为时间对象
        var time1 = new Date(moment(this.queryParams.start_month).format('YYYY-MM'));
        var time2 = new Date(moment(this.queryParams.end_month).format('YYYY-MM'));
        // 比较时间对象的毫秒数
        if (time1.getTime() > time2.getTime()) {
          this.$message.error('统计时间选择错误，开始月份应该小于等于结束月份');
          return
        }
      }
      this.tableLoading = true;

      // let userdepid = this.queryParams.userdepid;
      // if(userdepid.indexOf('|') > -1) {
      //   userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      // }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        start_month: this.queryParams.start_month?this.queryParams.start_month+'-01':"",
        end_month: this.queryParams.end_month?this.queryParams.end_month+'-31':"",
      };
      console.log(params)
      getPropertyProjectInspectionListByCondition(params).then(res => {
        this.tableLoading = false;
        console.log(res.item)
        if (res && res.returncode === '0') {
          this.tableData = res.item?res.item.map(item => ({
            ...item,
            month: item.month.slice(0, -3)
          })):[]
          this.pagination.total = res.count;
        }
      }).catch(err => {
        console.log(err)
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id=0
        this.modalForm.month= null
        this.modalForm.patroltype=''
        this.modalForm.patrollinenum= ''
        this.modalForm.patrolnum= ''
        this.modalForm.patroldaynum= ''
        this.modalForm.userdepid= ''
        this.modalForm.selectDeptList= []
        this.modalForm.monitorpointnum= ''
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true
        this.modalForm.id=record.id
        this.modalForm.month= record.month
        this.modalForm.patroltype=record.patroltype+''
        this.modalForm.patrollinenum= record.patrollinenum
        this.modalForm.patrolnum= record.patrolnum
        this.modalForm.patroldaynum= record.patroldaynum
        this.modalForm.userdepid = record.userdep_id
        this.modalForm.monitorpointnum = record.monitorpointnum
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(propertyProjectInspection_id) {
      if (propertyProjectInspection_id) {
        let params = {
          id:propertyProjectInspection_id
        };
        deleteOnePropertyProjectInspection(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑垃圾处理单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      for (let item of this.monitorpointList) {
        if (String(item.userdepid) === userdepid) {
          this.modalForm.monitorpointnum = item.monitorpointnum
        }
      }
      let params = {
        month:this.modalForm.month+"-01",
        patroltype: this.modalForm.patroltype,
        patrollinenum: this.modalForm.patrollinenum,
        patrolnum: this.modalForm.patrolnum,
        patroldaynum: this.modalForm.patroldaynum,
        monitorpointnum: this.modalForm.monitorpointnum,
        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        console.log(params)
        addPerformancePropertyProjectInspection(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editPropertyProjectInspection(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedPropertyProjectInspection = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedPropertyProjectInspection)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedPropertyProjectInspection)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedPropertyProjectInspection)
      } else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedPropertyProjectInspection)
      }
    },
    handleClickRow(record,index){
      console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
    monthChange(date,dateString){
      this.modalForm.month=dateString;
    },
    startMonthChange(date,dateString){
      this.queryParams.start_month=dateString;
    },
    endMonthChange(date,dateString){
      this.queryParams.end_month=dateString;
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.propertyProjectInspections_status-view {
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #65ff18;
    border-radius: 50%;
    &.red {
      background-color: #e70c0c;
    }
  }
  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
.propertyProjectInspections_people_type-view {
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #1859ff;
    border-radius: 50%;
    &.green {
      background-color: #65ff18;
    }
  }
  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
</style>